
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  inject,
  PropType,
  ref,
} from 'vue';
import { useStore } from 'vuex';
import { ApmBase } from '@elastic/apm-rum';

/** Interface */
import { AttributesGeneralI } from '@/interfaces/search-information.interface';
import { ResponseSaveNegotiationI } from '@/interfaces/accept-conditions.interface';
import {
  NegotiationWithinSimulatorI,
  SimulateNegotiationI,
} from '@/interfaces/negotiation.interface';

/** Utilidades */
import {
  closeSwal,
  showSwalError,
  showSwalLoading,
} from '@/utils/sweetalert2-utils';
import { showErrors, disableEncryption } from '@/utils/general-utils';
import { useUtilities } from '@/composition/useUtilities';
import { EncryptService } from '@/services/encrypt.service';

/**Composable */
import { useElasticTransactionApm } from '@/composition/useElasticTransactionApm';

export default defineComponent({
  name: 'ModalNegotiation',
  components: {
    ModalConfirmNegotiation: defineAsyncComponent(
      () => import('@/components/shared/ModalConfirmNegotiation.vue')
    ),
    Modal: defineAsyncComponent(
      () => import('@/components/shared/NewModal.vue')
    ),
  },
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    portfolio: {
      type: Object,
      required: false,
    },
    client: {
      type: Object as PropType<AttributesGeneralI>,
      required: false,
    },
  },
  emits: ['close', 'open-modal-gratitude'],
  setup(_props, { emit }) {
    const store = useStore();
    const $apm = inject('$apm') as ApmBase;

    const { customTransactioncApm, addTransaction, endTransactionApm } =
      useElasticTransactionApm();
    const showModalConfirmNegotiation = ref<boolean>(false);
    const currentNegotiation = ref<SimulateNegotiationI>(Object.create({}));
    const { setHomologateProductsTitle } = useUtilities();

    const timestamp = computed((): string => {
      const dateUpdate = store.getters.getTimestamp;
      return dateUpdate ? new Date(dateUpdate).toLocaleDateString() : '';
    });

    const dataSimulator = computed((): SimulateNegotiationI[] => {
      return store.state.dataNegotiation;
    });

    const close = () => {
      emit('close');
    };

    const confirmAcceptConditions = (negotiation: SimulateNegotiationI) => {
      customTransactioncApm(
        $apm,
        'click - quiero-este-trato',
        'custom',
        negotiation
      );
      showModalConfirmNegotiation.value = true;
      currentNegotiation.value = negotiation;
      endTransactionApm();
    };

    const actionCall = () => {
      customTransactioncApm($apm, 'click - llamanos-a-nuestra-linea', 'custom');
      const url =
        'https://customers.wolkvox.com/clicktocall/refinancia/?documento=';
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
      endTransactionApm();
    };

    const actionChat = () => {
      customTransactioncApm($apm, 'click - chat-en-linea-por-wsp', 'custom');
      const url = 'https://wa.link/k8qprh';
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
      endTransactionApm();
    };

    const actionCallYou = () => {
      customTransactioncApm(
        $apm,
        'click - explorar-un-trato-diferente',
        'custom'
      );
      const url =
        'https://forms.office.com/Pages/ResponsePage.aspx?id=d1iQS8-ex0qlI2vIg4XmdGEgTnPn7txNvRXxMtO8eGlURERRVzRXOEZDUklJSUNMUFFVSTFTWlFVQS4u';
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
      /* redirectWhatsappAdviser(
        process.env.VUE_APP_WHATSAPP_GESTOR,
        'Necesito más opciones para pagar mi obligación'
      ); */
      endTransactionApm();
    };

    const closeModalConfirmNegotiation = () => {
      showModalConfirmNegotiation.value = false;
    };

    const createNegatiation = (id: string): NegotiationWithinSimulatorI => {
      const resultNegotiation = dataSimulator.value.filter(
        (element: SimulateNegotiationI) => element?.product_id === id
      );

      return {
        ...resultNegotiation[0]?.negotiation,
        platform_id: 2,
      };
    };

    const aceptCondition = () => {
      closeModalConfirmNegotiation();
      const productId = currentNegotiation.value?.product_id?.toString() || '';
      const negotiation = createNegatiation(productId);
      const errorMessage =
        'Se produjo un error al realizar la negociación, intente mas tarde.';
      customTransactioncApm($apm, 'click - confirmar', 'custom', negotiation);
      showSwalLoading();
      store
        .dispatch('negotiationsSave', negotiation)
        .then((response: ResponseSaveNegotiationI) => {
          const { attributes, success } = response.data;
          addTransaction(response.data);
          if (success) {
            const [savedNegotiation] = attributes;
            if (savedNegotiation?.success) {
              closeSwal();
              emit('open-modal-gratitude', savedNegotiation.negotiation_id);
            } else {
              showSwalError(errorMessage);
            }
          } else {
            showSwalError(errorMessage);
          }
        })
        .catch((err) => {
          const data = disableEncryption()
            ? err.response.data
            : EncryptService.decrypt(err.response.data);
          const error = data.error || data.message;
          if (error.includes('errores')) {
            showSwalError(
              'Lo sentimos, su obligación presenta novedades por lo cual no es posible procesar su trato. Lo invitamos a comunicarse con nosotros al (+57) 322 9482917, donde uno de nuestros asesores comerciales cargará el acuerdo'
            );
          } else {
            showErrors(err, errorMessage);
          }
        });
    };

    const setDiscount = (discountValue: number) => {
      return discountValue ?? 0;
    };

    const setConvertPercent = (percentValue: number) => {
      return percentValue * 100;
    };

    const moreTime = () => {
      customTransactioncApm($apm, 'click - necesito-mas-tiempo', 'custom', {});
      closeModalConfirmNegotiation();
      endTransactionApm();
    };

    return {
      aceptCondition,
      close,
      closeModalConfirmNegotiation,
      confirmAcceptConditions,
      actionCall,
      actionChat,
      actionCallYou,
      currentNegotiation,
      dataSimulator,
      moreTime,
      setConvertPercent,
      setDiscount,
      setHomologateProductsTitle,
      showModalConfirmNegotiation,
      timestamp,
    };
  },
});
